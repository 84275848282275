<template>
  <div>
    <TicketFilter
      :ticket-type="ticketType"
      :search-incident.sync="filter.searchIncident"
      :search-priority.sync="filter.searchPriority"
      :search-status.sync="filter.ticketStatus"
      :search-customer-name.sync="filter.searchCustomerName"
      :search-phone-number.sync="filter.searchPhoneNumber"
      :search-progress-by.sync="filter.searchProgressBy"
      :search-ticket-id.sync="filter.searchTicketId"
      :search-subject.sync="filter.searchSubject"
      :search-user-group.sync="filter.searchUserGroup"
      :search-transaction.sync="filter.searchTransaction"
      :user-group="userGroup"
      :start-date.sync="filter.startDate"
      :end-date.sync="filter.endDate"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Open Ticket Tab"
        to-path="/ticket/add"
        @input="fetchFilter()" />
      <div>
        <b-table
          striped
          hover
          responsive
          :items="items"
          :fields="fields"
          @sort-changed="sortChanged"
          @row-clicked="rowClick($event)">
          <template #cell(code)="{item}">
            <div
              v-if="item.code"
              class="d-flex">
              <div>
                {{ item.code }}
              </div>
              <div
                v-if="item.isTransfer"
                class="ml-2">
                <img
                  width="25"
                  height="25"
                  alt="Transfer"
                  src="@/assets/images/icons/transfer.png"
                />
              </div>
              <div
                v-if="item.isNewEmail"
                class="ml-2">
                <img
                  width="25"
                  height="25"
                  alt="Email Notification"
                  src="@/assets/images/icons/notification-email.png"
                />
              </div>
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(incidentType)="data">
            <div v-if="data.item.tickeType && data.item.tickeType.name">
              {{ data.item.tickeType.name }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div v-if="data.item.createdAt">
              {{ data.item.createdAt | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(updatedAt)="data">
            <div v-if="data.item.updatedAt">
              {{ data.item.updatedAt | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(createdBy)="data">
            <div v-if="data.item && data.item.createdBy ">
              {{ data.item.createdBy.username }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(progressBy)="data">
            <div v-if="data.item && data.item.progressBy.firstName && data.item.progressBy.lastName ">
              {{ data.item.progressBy.firstName }} {{ data.item.progressBy.lastName }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(dueDate)="data">
            <div v-if="data.item.dueDate">
              {{ data.item.dueDate | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(resolveDate)="data">
            <div v-if="data.item.resolveDate">
              {{ data.item.resolveDate | formatDateTimeEnShort }}
            </div>
            <div v-else>
              -
            </div>
          </template>
          <template #cell(contactChannel)="{item}">
            <div v-if="item && item.contactChannel && item.contactChannel.name">
              {{ item.contactChannel.name }}
            </div>
          </template>
          <template #cell(ticketStatus)="data">
            <b-badge :variant="$ticketStatus(data.value)">
              {{ data.value | formatTicketStatus }}
            </b-badge>
            <svg xmlns="http://www.w3.org/2000/svg" v-if="data.item.remark2" v-b-popover.hover.right="data.item.remark2" title="Note" class="icon icon-tabler icon-tabler-notes" width="28" height="28" viewBox="0 0 24 24" stroke-width="1.5" stroke="#7367f0" fill="none" stroke-linecap="round" stroke-linejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
            <path d="M9 7l6 0" />
            <path d="M9 11l6 0" />
            <path d="M9 15l4 0" />
            </svg>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TableTop from '@/components/TableTop.vue'
import TicketProvider from '@/resources/TicketProvider'
import TicketTypeProvider from '@/resources/TicketType'
import UserGroupProvider from '@/resources/UserGroupProvider'
import TicketFilter from './components/TicketFilter.vue'

const TicketTypeService = new TicketTypeProvider()
const TicketService = new TicketProvider()
const UserGroup = new UserGroupProvider()

export default {
  components: {
    TicketFilter,
    TableTop
  },
  data () {
    const {
      ticketStatus,
      startDate,
      endDate
    } = this.$route.query
    return {
      isDeleteModalActive: false,
      userGroup: [],
      sort: 'createdAt',
      sortDesc: true,
      filter: {
        searchIncident: '',
        searchPriority: '',
        searchCustomerName: '',
        searchPhoneNumber: '',
        searchProgressBy: '',
        searchTransaction: '',
        searchUserGroup: [],
        ticketStatus: ticketStatus || '',
        searchTicketId: '',
        searchSubject: '',
        startDate: startDate || '',
        endDate: endDate || '',
        sort: 'createdAt',
        sortOrder: 'DESC'
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'ticketStatus', label: 'Status', sortable: false, thStyle: { width: '160px' } },
        { key: 'code', label: 'Ticket No.', sortable: true },
        { key: 'ticketCodeRefer', label: 'Refer No.', sortable: false },
        { key: 'contactChannel', label: 'Contact Channel', sortable: false },
        { key: 'transactionId', label: 'Transaction ID (Non-Voice)', sortable: false },
        { key: 'incidentType', label: 'Ticket Type', sortable: false },
        { key: 'subject', label: 'subject', sortable: false },
        { key: 'customerName', label: 'Customer Name', sortable: false },
        { key: 'contactName', label: 'Contact Name ', sortable: false },
        { key: 'createdAt', label: 'CreateAt', sortable: true },
        { key: 'progressBy', label: 'ProgressBy', sortable: false },
        { key: 'dueDate', label: 'DueDate', sortable: true }
      ],
      items: [],
      selectedItem: {},
      initialTicketType: {},
      deleteItem: {},
      ticketType: []
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    })
  },
  mounted () {
    this.getTicket()
    this.getTicketType()
    this.getUserGroup()
  },
  methods: {
    async getTicket () {
      try {
        const filter = { ...this.filter }
        if (this?.$route?.query?.from === 'dashboard') {
          filter.from = 'dashboard'
          this.$router.replace({ query: null })
        }
        filter.searchPhoneNumber = this.$replacePhoneNumber(filter.searchPhoneNumber, '+66')
        const data = await TicketService.paginate(this.pagination.page, this.pagination.limit, { ...filter, userId: this.profile.id })
        this.setPagination(data.page, data.totalDocs)
        this.items = [...data.data]
      } catch (error) {
        console.error(error)
      }
    },
    async getTicketType () {
      try {
        const { data } = await TicketTypeService.findAllType()
        this.ticketType = data
      } catch (error) {
        console.error(error)
      }
    },
    async getUserGroup () {
      try {
        const { data } = await UserGroup.findAllGroupCode()
        this.userGroup = data
      } catch (error) {
        console.error(error)
      }
    },
    rowClick (val) {
      this.selectedItem = { ...val }
      this.initialTicketType = { ...val }
      this.$router.push(`/ticket/detail/${val.id}`)
    },
    fetchFilter () {
      this.setPagination(1)
      this.getTicket()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getTicket()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs === 0 ? 0 : totalDocs || this.pagination.totalDocs
    },
    sortChanged (event) {
      this.filter.sort = event.sortBy || 'id'
      this.filter.sortOrder = event.sortDesc ? 'DESC' : 'ASC'
      if (event.sortBy === 'createdAt') {
        this.filter.sort = 'id'
      }
      this.getTicket()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-select {
  background: white;
}
.card-body {
  padding: 0;
}
::v-deep input.vs__search::placeholder {
  color: #B9B9C3;
}
.zoom-80 {
  zoom: 80%;
}
</style>
